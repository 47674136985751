import React, { useState, useEffect } from "react";
import PageLayout from "../../components/pageLayout/pageLayout";
import axios from "axios";
import { Autocomplete, Button, TextField } from "@mui/material";
import camera from "../../images/camera.png";

function WebcamMap() {
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [client, setClient] = useState("All Clients");
  const [events, setEvents] = useState([]);
  const [clients, setClients] = useState([]);
  const [webcam, setWebcam] = useState(
    "https://webcams.windy.com/webcams/public/embed/player/1512058312/day"
  );

  const options = {
    key: "xyz",
    lon: -103.77155,
    lat: 44.96724,
    zoom: 5,
    key: "K2hZbr6Ccju0IAKMfARbfHHhplR8SBe5",
    preferCanvas: true,
  };

  useEffect(() => {
    axios
      .get(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=alerts`
      )
      .then((res) => {
        let arr = res.data[0].contracts.filter((contract) => contract.lat);
        setClients([
          ...new Set(res.data[0].contracts.map((site) => site.client).sort()),
        ]);
        setFilteredEvents(arr);
        setEvents(arr);
      });
  }, []);

  const getCameras = () => {
    let arr = [...events];

    arr.forEach((event, index) => {
      // if (event.lat && event.lng) {
      //   axios
      //     .get(
      //       `https://my-tb-cors.herokuapp.com/https://api.windy.com/webcams/api/v3/webcams?lang=en&limit=50&offset=0&nearby=${event.lat}%2C${event.lng}%2C3&include=categories`,
      //       {
      //         headers: {
      //           "x-windy-api-key": "wh5zdd968j171JKy156RKkzaS9eA1OIz",
      //           accept: "application/json",
      //         },
      //       }
      //     )
      //     .then((res) => {
      //       //console.log(res);
      //       if (res.data.webcams.length > 0) {
      //         const webcam = res.data.webcams[0];
      //         const { webcamId } = webcam;
      //         arr[index].webcamId = webcamId;
      //       }

      //       console.log(index);
      //       console.log(arr);
      //     });
      // }
      if (event.webcamId) {
        axios
          .get(
            `https://my-tb-cors.herokuapp.com/https://api.windy.com/webcams/api/v3/webcams/${event.webcamId}?lang=en&include=player`,
            {
              headers: {
                "x-windy-api-key": "wh5zdd968j171JKy156RKkzaS9eA1OIz",
                accept: "application/json",
              },
            }
          )
          .then((res) => {
            arr[index].webcamPlayer = res.data?.player?.day;
            console.log(index);
            console.log(arr);
          });
      }
    });
  };

  const getCameraById = (webcamId) => {
    axios
      .get(
        `https://my-tb-cors.herokuapp.com/https://api.windy.com/webcams/api/v3/webcams/${webcamId}?lang=en&include=player`,
        {
          headers: {
            "x-windy-api-key": "wh5zdd968j171JKy156RKkzaS9eA1OIz",
            accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log(res);
      });
  };

  useEffect(() => {
    if (filteredEvents.length > 0) {
      console.log("running");
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.windyInit(options, (windyAPI) => {
        const { map, store, picker, utils, overlays } = windyAPI;
        console.log(overlays);
        overlays.wind.setMetric("mph");
        overlays.snowAccu.setMetric("in");
        overlays.snowcover.setMetric("in");

        // Handle map click event
        map.on("click", (e) => {
          // Open the picker at the clicked coordinates
          picker.open({ lat: e.latlng.lat, lon: e.latlng.lng });
        });

        // Create a custom icon
        const customIcon = window.L.icon({
          iconUrl: camera,
          iconSize: [16, 16], // Adjust the size as needed
          iconAnchor: [16, 32], // Adjust the anchor point if necessary
          popupAnchor: [0, -32], // Adjust the popup anchor point if necessary
        });

        filteredEvents.forEach((event) => {
          if (event.webcamPlayer) {
            window.L.marker([event.lat, event.lng], {
              icon: customIcon,
            })
              .addTo(map)
              .bindPopup(
                `
                Client: ${event.client}<br />
                Store: ${event.store}<br />
                Address: ${event.address}<br />
                City: ${event.city}, ${event.state} ${event.zipcode}<br />
                Zip code: ${event.zipcode}<hr />
                Sub: ${event.subName || "None assigned"}<br />
                Sub Phone: ${event.subPhone || ""}<br /><br />
                ${
                  event.webcamPlayer
                    ? `<iframe src="${event.webcamPlayer}" style={{width: '100px', height: '100px'}}></iframe>`
                    : ""
                }
              `
              );
          }
        });
      });
    }
  }, [filteredEvents]);

  const handleFilter = (e, newValue) => {
    let arr = [];

    if (newValue === "All Clients") {
      arr = events;
    } else if (newValue === "Washington Clients") {
      arr = events.filter((event) => event.state === "WA");
    } else {
      arr = events.filter((event) => event.client === newValue);
    }
    setClient(newValue);
    setFilteredEvents(arr);
  };

  return (
    <PageLayout page="Web Cams">
      <Autocomplete
        value={client}
        options={["All Clients", "Washington Clients", ...clients]}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by Client"
            size="small"
            sx={{ mb: 2, width: "400px", bgcolor: "white" }}
          />
        )}
        onChange={handleFilter}
      />
      <div id="windy" style={{ width: "100%", height: "80vh" }} />
    </PageLayout>
  );
}

export default WebcamMap;
