import React, { useState, useEffect } from "react";
import PageLayout from "../../components/pageLayout/pageLayout";
import axios from "axios";
import { Autocomplete, TextField } from "@mui/material";

function NewNewMap() {
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [client, setClient] = useState("All Clients");
  const [events, setEvents] = useState([]);
  const [clients, setClients] = useState([]);

  const options = {
    key: "xyz",
    lon: -103.77155,
    lat: 44.96724,
    zoom: 5,
    key: "ZLpql2pKxb5fsI2AVsfgs0ozUGwxlEMz",
    preferCanvas: true,
  };

  useEffect(() => {
    axios
      .get(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=alerts`
      )
      .then((res) => {
        let arr = res.data[0].contracts.filter((contract) => contract.lat);
        setClients([
          ...new Set(res.data[0].contracts.map((site) => site.client).sort()),
        ]);
        setFilteredEvents(arr);
        setEvents(arr);
      });
  }, []);

  useEffect(() => {
    if (filteredEvents.length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.windyInit(options, (windyAPI) => {
        const { map } = windyAPI;

        filteredEvents.forEach((event) => {
          window.L.circleMarker([event.lat, event.lng], {
            color: event.subName ? "#39ff14" : "red",
            radius: 5,
          }).addTo(map).bindPopup(`Client: ${event.client}<br />
                                    Store: ${event.store}<br />
                                    Address: ${event.address}<br />
                                    City: ${event.city}, ${event.state} ${
            event.zipcode
          }<br />
                                    Zip code: ${event.zipcode}<hr />
                                    Sub: ${
                                      event.subName || "None assigned"
                                    }<br />
                                    Sub Phone: ${event.subPhone || ""}<br />`);
        });
      });
    }
  }, [filteredEvents]);

  const handleFilter = (e, newValue) => {
    let arr = [];

    if (newValue === "All Clients") {
      arr = events;
    } else if (newValue === "Washington Clients") {
      arr = events.filter((event) => event.state === "WA");
    } else {
      arr = events.filter((event) => event.client === newValue);
    }

    console.log(arr);
    setClient(newValue);
    setFilteredEvents(arr);
  };

  return (
    <PageLayout page="Map">
      <Autocomplete
        value={client}
        options={["All Clients", "Washington Clients", ...clients]}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by Client"
            size="small"
            sx={{ mb: 2, width: "400px", bgcolor: "white" }}
            // fullWidth
            // error={formValues.property === '' && submitted}
          />
        )}
        onChange={handleFilter}
      />
      <div id="windy" style={{ width: "100%", height: "100vh" }} />
    </PageLayout>
  );
}

export default NewNewMap;
