import axios from "axios";
import React, { useEffect, useState } from "react";
import PageLayout from "../../components/pageLayout/pageLayout";
import GoogleMapReact from "google-map-react";
import {
  Autocomplete,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import MapMarker from "./marker";

function Map() {
  const [events, setEvents] = useState([]);
  const [activeEvent, setActiveEvent] = useState(null);
  const [zoom, setZoom] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [center, setCenter] = useState([44.967243, -103.77155]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("All Clients");

  useEffect(() => {
    axios
      .get(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=alerts`
      )
      .then((res) => {
        console.log([
          ...new Set(res.data[0].contracts.map((site) => site.client).sort()),
        ]);

        setClients([
          ...new Set(res.data[0].contracts.map((site) => site.client).sort()),
        ]);
        //setFilteredEvents(res.data[0].contracts);
        //console.log(res.data[0].contracts);

        let arr = res.data[0].contracts.filter((contract) => contract.lat);
        setFilteredEvents(arr);
        setEvents(arr);

        //console.log(res.data[0].contracts.filter(event => !event.subbed))
      });
  }, []);

  // changes that were made:
  // filtered only to canada sites
  // made all sites red
  // changed pins to dots

  function updateCenter(newValue) {
    let newCenter = [newValue.lat, newValue.lng];
    setZoom(9);
    setCenter([...newCenter]);
  }

  const handleFilter = (e, newValue) => {
    setClient(newValue);
    if (newValue === "All Clients") {
      setFilteredEvents(events);
    } else if (newValue === "Washington Clients") {
      setFilteredEvents(events.filter((event) => event.state === "WA"));
    } else {
      setFilteredEvents(events.filter((event) => event.client === newValue));
    }
  };

  return (
    <PageLayout page="Map">
      {/* <Autocomplete
				freeSolo
				renderInput={(params) => (
					<TextField
						{...params}
						label='Search Sites'
						size='small'
						sx={{ width: '200px' }}
					/>
				)}
				getOptionLabel={(option) => option.address}
				options={events}
				className='mb-2'
				onChange={(e, newValue) => updateCenter(newValue)}
				sx={{ mb: 2, width: '200px' }}
			/> */}
      <Autocomplete
        value={client}
        options={["All Clients", "Washington Clients", ...clients]}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Filter by Client"
            size="small"
            sx={{ mb: 2, width: "400px", bgcolor: "white" }}
            // fullWidth
            // error={formValues.property === '' && submitted}
          />
        )}
        onChange={handleFilter}
      />
      <Grid container>
        <Grid item xs={9}>
          <div style={{ height: "100%", width: "100%" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyA6OXdC0qAWY2JhTHhl--BaUnfzWBN9XH4",
              }}
              //defaultCenter={center}
              zoom={zoom}
              center={center}
            >
              {filteredEvents.map((event) => {
                return (
                  <MapMarker
                    lat={event.lat}
                    lng={event.lng}
                    key={event.id}
                    title={event.siteName || ""}
                    event={event}
                    setActiveEvent={setActiveEvent}
                    active={activeEvent === event}
                  />
                );
              })}
            </GoogleMapReact>
          </div>
        </Grid>
        <Grid item xs={3}>
          <Paper sx={{ p: 3, height: "calc(100vh - 150px)" }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Selected Location:
            </Typography>
            {activeEvent && (
              <>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Client: {activeEvent.client || ""}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Store: {activeEvent.store || ""}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Address: {activeEvent.address || ""}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  City: {activeEvent.city || ""}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  State: {activeEvent.state || ""}
                </Typography>
                <Typography variant="body2" sx={{ mb: 1 }}>
                  Zip Code: {activeEvent.zipcode || ""}
                </Typography>

                <Divider sx={{ mt: 1, mb: 2 }} />

                <Typography variant="body2" sx={{ mb: 1 }}>
                  Subcontractor: {activeEvent.subName || "None assigned"}
                </Typography>
                <Typography variant="body2">
                  Sub Phone: {activeEvent.subPhone || ""}
                </Typography>
                {/* <Typography variant="body2">
                                    Sub Email: {activeEvent.subEmail || ""}
                                </Typography> */}
              </>
            )}
            {!activeEvent && (
              <Typography variant="body2">
                Click on a location to see it's information
              </Typography>
            )}
          </Paper>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

export default Map;
