import React, { useState } from "react";
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ButtonBox from "../components/buttonBox/buttonBox";
import PropTypes from "prop-types";

function EditContract({ initialValues, updateContract, setEditModalOpen }) {
  const [contract, setContract] = useState(initialValues);

  return (
    <>
      <TextField
        value={contract.client}
        size="small"
        onChange={(e) =>
          setContract({
            ...contract,
            client: e.target.value,
          })
        }
        label="Client Name ie Walmart"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={contract.store}
        size="small"
        onChange={(e) =>
          setContract({
            ...contract,
            store: e.target.value,
          })
        }
        label="Store #"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={contract.address}
        size="small"
        onChange={(e) =>
          setContract({
            ...contract,
            address: e.target.value,
          })
        }
        label="Address"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={contract.city}
        size="small"
        onChange={(e) =>
          setContract({
            ...contract,
            city: e.target.value,
          })
        }
        label="City"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={contract.zipcode}
        size="small"
        onChange={(e) =>
          setContract({
            ...contract,
            zipcode: e.target.value,
          })
        }
        label="Zipcode"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={contract.subName}
        size="small"
        onChange={(e) =>
          setContract({
            ...contract,
            subName: e.target.value,
          })
        }
        label="Sub Email"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={contract.subPhone}
        size="small"
        onChange={(e) =>
          setContract({
            ...contract,
            subPhone: e.target.value,
          })
        }
        label="Sub Phone"
        fullWidth
        sx={{ mb: 2 }}
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            value={contract.lat}
            size="small"
            onChange={(e) =>
              setContract({
                ...contract,
                lat: e.target.value,
              })
            }
            label="Latitude"
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={contract.lng}
            size="small"
            onChange={(e) =>
              setContract({
                ...contract,
                lng: e.target.value,
              })
            }
            label="Longitude"
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
      </Grid>

      <ButtonBox>
        <Button
          sx={{ fontSize: "10px", mr: 1 }}
          variant="contained"
          color="error"
          onClick={() => {
            setEditModalOpen(false);
            setContract(initialValues);
          }}
        >
          cancel
        </Button>
        <Button
          sx={{ fontSize: "10px" }}
          variant="contained"
          color="success"
          onClick={() => updateContract(contract)}
        >
          save contract
        </Button>
      </ButtonBox>
    </>
  );
}

EditContract.propTypes = {
  //   children: PropTypes.oneOfType([
  //     PropTypes.arrayOf(PropTypes.node),
  //     PropTypes.node,
  //   ]),
  setUploadModalOpen: PropTypes.func,
  initialValues: PropTypes.object,
  setEditModalOpen: PropTypes.func,
  updateContract: PropTypes.func,
};

export default EditContract;
