import { Box } from "@mui/material";
import * as React from "react";
import PropTypes from 'prop-types'

function ButtonBox(props) {
    const { children } = props;

    return(
        <Box sx={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
            { children}
        </Box>
    )
}

ButtonBox.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}

export default ButtonBox;