import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Paper,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import ButtonBox from "../components/buttonBox/buttonBox";
import PropTypes from "prop-types";

function NewContract(props) {
  const { saveContract, setUploadModalOpen, initialValues } = props;

  const [newContract, setNewContract] = useState(initialValues);

  return (
    <>
      <TextField
        value={newContract.Client}
        size="small"
        onChange={(e) =>
          setNewContract({
            ...newContract,
            Client: e.target.value,
          })
        }
        label="Client Name ie Walmart"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={newContract.siteName}
        size="small"
        onChange={(e) =>
          setNewContract({
            ...newContract,
            siteName: e.target.value,
          })
        }
        label="Site Name"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={newContract.Store}
        size="small"
        onChange={(e) =>
          setNewContract({
            ...newContract,
            Store: e.target.value,
          })
        }
        label="Store Number"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={newContract.Address}
        size="small"
        onChange={(e) =>
          setNewContract({
            ...newContract,
            Address: e.target.value,
          })
        }
        label="Address"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={newContract.City}
        size="small"
        onChange={(e) =>
          setNewContract({
            ...newContract,
            City: e.target.value,
          })
        }
        label="City"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={newContract.Zipcode}
        size="small"
        onChange={(e) =>
          setNewContract({
            ...newContract,
            Zipcode: e.target.value,
          })
        }
        label="Zipcode"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={newContract.subPhone}
        size="small"
        onChange={(e) =>
          setNewContract({
            ...newContract,
            subPhone: e.target.value,
          })
        }
        label="Sub Phone"
        fullWidth
        sx={{ mb: 2 }}
      />

      <TextField
        value={newContract.subEmail}
        size="small"
        onChange={(e) =>
          setNewContract({
            ...newContract,
            subEmail: e.target.value,
          })
        }
        label="Sub Email"
        fullWidth
        sx={{ mb: 2 }}
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            value={newContract.lat}
            size="small"
            onChange={(e) =>
              setNewContract({
                ...newContract,
                lat: e.target.value,
              })
            }
            label="Latitude"
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={newContract.lng}
            size="small"
            onChange={(e) =>
              setNewContract({
                ...newContract,
                lng: e.target.value,
              })
            }
            label="Longitude"
            fullWidth
            sx={{ mb: 2 }}
          />
        </Grid>
      </Grid>
      <FormControl fullWidth size="small">
        <InputLabel id="on-call-label">On Call Only</InputLabel>
        <Select
          value={newContract.onCall}
          onChange={(e) =>
            setNewContract({
              ...newContract,
              onCall: e.target.value,
            })
          }
          label="On Call Only"
          labelId="on-call-label"
        >
          <MenuItem value={false}>No</MenuItem>
          <MenuItem value={true}>Yes</MenuItem>
        </Select>
        <FormHelperText sx={{ mb: 2 }}>
          Select Yes if we are only supposed to service when client requests
          service
        </FormHelperText>
      </FormControl>

      <TextField
        value={newContract.url}
        size="small"
        onChange={(e) =>
          setNewContract({
            ...newContract,
            url: e.target.value,
          })
        }
        label="Weather Forecast Graph URL"
        fullWidth
        sx={{ mb: 2 }}
      />

      <ButtonBox>
        <Button
          sx={{ fontSize: "10px", mr: 1 }}
          variant="contained"
          color="error"
          onClick={() => {
            setUploadModalOpen(false);
            setNewContract(initialValues);
          }}
        >
          cancel
        </Button>
        <Button
          sx={{ fontSize: "10px" }}
          variant="contained"
          color="success"
          onClick={() => saveContract(newContract)}
        >
          save contract
        </Button>
      </ButtonBox>
    </>
  );
}

NewContract.propTypes = {
  //   children: PropTypes.oneOfType([
  //     PropTypes.arrayOf(PropTypes.node),
  //     PropTypes.node,
  //   ]),
  saveContract: PropTypes.func,
  setUploadModalOpen: PropTypes.func,
  initialValues: PropTypes.object,
};

export default NewContract;
