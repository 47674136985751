import React, { useEffect } from "react";
import { useClearCache } from "react-clear-cache";
import { LicenseInfo } from "@mui/x-license-pro";
import { Route, Routes } from "react-router-dom";

import Login from "./pages/login/login";
import Contracts from "./pages/contracts/contracts";
import Map from "./pages/map/map";
import NewMap from "./pages/map/newMap";
import NewNewMap from "./pages/map/newNewMap";
import WindyMap from "./pages/windyMap/windyMap";
import WebcamMap from "./pages/windyMap/webcams";

LicenseInfo.setLicenseKey(
  "f94999c7685f2608811fb8b48423f2baTz00ODIyNyxFPTE2OTA3Mzk3NTYzNjgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI="
);

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  useEffect(() => {
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  }, []);

  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route exact path="/contracts" element={<Contracts />} />
      <Route exact path="/map" element={<NewMap />} />
      <Route exact path="/windy" element={<WindyMap />} />
      <Route exact path="/webcams" element={<WebcamMap />} />
    </Routes>
  );
}

export default App;
