import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Button } from '@mui/material';
import PropTypes from 'prop-types'

import PageLayoutLogic from './pageLayoutLogic';
import logo from '../../images/logo.png';
import { useNavigate } from 'react-router-dom';

function PageLayout(props) {
    const navigate = useNavigate()
    const { page } = props;
    const [location, setLocation] = React.useState('Monroe');

    const {
        theme,
        open,
        AppBar,
        DrawerHeader,
        Drawer,
        navLinks,
        handleDrawerClose,
        handleDrawerOpen,
        logout
    } = PageLayoutLogic();

    const { children } = props;

    useEffect(() => {
        let account = localStorage.getItem('account');
        if(!account) {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        // force user to relogin after 24 hours
        let lastLoginTime = localStorage.getItem('lastLoginTime');
        let currentTime = new Date().getTime();
        if(currentTime - lastLoginTime > 86400000 || !lastLoginTime) {
            navigate('/')
        }
    }, []);


    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open} sx={{bgcolor: '#0b56a4'}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{
                            marginRight: 5,
                            ...(open && { display: 'none' }),
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                        Transblue Weather Alerts
                    </Typography>

                    <Button
                        sx={{bgcolor: 'white', color: 'red'}} 
                        variant='contained'
                        component='a'
                        target='_blank'
                        href='https://app.smartsheet.com/b/form/f5fbb266e09141e2a6b35dbf89dee746'
                    >
                        FEEDBACK!!!
                    </Button>
                    <Button color="inherit" onClick={logout} >Logout</Button>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <img src={logo} alt='' style={{display: 'block', margin: '0 auto', width: '50%'}} />
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {navLinks.map((link, index) => (
                        <ListItemButton
                            component='a'
                            href={link.href}
                            key={link.text}
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5
                            }}
                        >
                            <ListItemIcon
                                sx={page === link.text ? {
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                    color: '#ed6a22'
                                } : {
                                    minWidth: 0,
                                    mr: open ? 3 : 'auto',
                                    justifyContent: 'center',
                                }}
                            >
                                {link.icon}
                            </ListItemIcon>
                            <ListItemText primary={link.text} sx={{ opacity: open ? 1 : 0 }} />
                        </ListItemButton>
                    ))}
                </List>
                <Divider />
               
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 2, bgcolor: '#f5f5f5', minHeight: '100vh', borderLeft: '1px solid #e4e5e7' }}>
                <DrawerHeader />
                { children }
            </Box>
        </Box>
    );
}
PageLayout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]),
    page: PropTypes.string
}
export default PageLayout;