import React from "react";
import { IconButton } from "@mui/material";
import { Circle, LocationOn } from "@mui/icons-material";
import { pure } from "recompose";
import { PropTypes } from "prop-types";

const MapMarker = (props) => {
  let { title, event, setActiveEvent, active } = props;
  let color = "red";

  if (event.subName === "Dylan") {
    color = "blue";
  } else if (event.subName) {
    color = "green";
  }

  // if(event.subName) {
  //     event.subName === 'GRM Inc'
  //         ?   color = 'red'
  //         :   color = 'green'
  // } else {
  //     color = 'red'
  // }

  // if(!event.subbed) {
  //     color='red'
  // }

  // else if(event.subPhone && event.subPhone.length == 0 && event.subEmail && event.subEmail.length === 0) {
  //     color = 'red'
  // }

  return (
    <IconButton
      onClick={() => setActiveEvent(event)}
      sx={{
        position: "absolute",
        position: "absolute",
        transform: "translate(-50%, -50%)",
      }}
    >
      {/* <Circle sx={{color: 'red', width: '10px'}}  /> */}

      <LocationOn sx={{ color: active ? "red" : color, width: "16px" }} />
    </IconButton>
  );
};

MapMarker.propTypes = {
  title: PropTypes.string,
  event: PropTypes.object,
  setActiveEvent: PropTypes.func,
  active: PropTypes.bool,
};

export default pure(MapMarker);
