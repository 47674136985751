import {
  Button,
  FormGroup,
  Grid,
  IconButton,
  Modal,
  Paper,
  TextField,
  Typography,
  Box,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Divider,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { redirect } from "react-router-dom";
import PageLayout from "../../components/pageLayout/pageLayout";
import { DataGridPro, GridActionsCellItem } from "@mui/x-data-grid-pro";
import ButtonBox from "../../components/buttonBox/buttonBox";
import { Edit, Search } from "@mui/icons-material";
import style from "../../styles/modal";
import { v4 as uuidv4 } from "uuid";
import NewContract from "../../forms/newContract";
import "./style.css";
import { useNavigate } from "react-router-dom";
import subs from "./subs";
import arr from "./walgreens";
import walgreens from "./stores";
import excel from "./excel";
import allCompanies from "./costar";
import uploaded from "./uploaded";
import EditContract from "../../forms/editContract";

var XLSX = require("xlsx");

function Contracts() {
  const navigate = useNavigate();
  let requiredCols = [
    "Client",
    "Site Name",
    "Store",
    "Address",
    "City",
    "Zipcode",
    "Sub Phone",
    "Sub Email",
    "Latitude",
    "Longitude",
    "On Call",
    "Image URL",
  ];
  const [validExcel, setValidExcel] = useState([]);
  const [excelError, setExcelError] = useState("");
  const [bulkLoading, setBulkLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [activeContract, setActiveContract] = useState({});
  const handleEditModalOpen = (contract) => {
    setEditModalOpen(true);
    setActiveContract(contract);
  };

  const location = "Monroe";
  const [contracts, setContracts] = useState([]);
  const [filteredContracts, setFilteredContracts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [error, setError] = useState("");
  const [uploadMethod, setUploadMethod] = useState("append");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [bulkModalOpen, setBulkModalOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const handleUploadClose = () => {
    setUploadModalOpen(false);
  };

  const handleUploadOpen = () => {
    setUploadModalOpen(true);
    setError("");
  };

  const [locationId, setLocationId] = useState("");
  const [excelJson, setExcelJson] = useState({});

  const [newJson, setNewJson] = useState([]);

  useEffect(() => {
    if (!location) {
      redirect("/");
    }
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=alerts`
      )
      .then((res) => {
        let doc = res.data.filter((loc) => loc.location === "Monroe");

        // handle if location does not exist in alerts container of database
        if (doc.length > 0) {
          setContracts(res.data[0].contracts);
          setFilteredContracts(res.data[0].contracts);
          setLocationId(res.data[0].id);
        }

        setLoading(false);
      });
  }, []);

  const columns = [
    {
      field: "client",
      headerName: "Client",
    },
    {
      field: "store",
      headerName: "Store #",
    },
    {
      field: "address",
      headerName: "Address",
    },
    {
      field: "city",
      headerName: "City",
    },
    {
      field: "zipcode",
      headerName: "Zipcode",
    },
    {
      field: "subName",
      headerName: "Sub Name",
    },
    {
      field: "subPhone",
      headerName: "Sub Phone",
    },
    {
      field: "lat",
      headerName: "Latitude",
    },
    {
      field: "lng",
      headerName: "Longitude",
    },
    {
      field: "actions",
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEditModalOpen(params.row)}
        />,
      ],
    },
  ];

  function selectRows(e) {
    setSelected(e);
  }

  function handleSearch(e) {
    if (e.target.value.length === 0) {
      setFilteredContracts(contracts);
    }

    setSearchTerm(e.target.value);
  }

  function search() {
    // search within each contract object
    let results = contracts.filter((obj) =>
      Object.keys(obj).some((key) => {
        if (typeof obj[key] === "string") {
          return obj[key]
            .toLowerCase()
            .trim()
            .includes(searchTerm.toLowerCase().trim());
        }
      })
    );

    setFilteredContracts(results);
  }

  function deleteSelected() {
    let arr = [...contracts];
    arr = arr.filter((contract) => selected.indexOf(contract.id) === -1);

    let filteredArr = [...filteredContracts];
    filteredArr = filteredArr.filter(
      (contract) => selected.indexOf(contract.id) === -1
    );

    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=alerts&id=${locationId}`,
        {
          contracts: arr,
        }
      )
      .then(() => {
        setDeleteModalOpen(false);
        setContracts(arr);
        setFilteredContracts(filteredArr);
      });
  }

  async function bulkUpload() {
    console.log(uploadMethod);
    setBulkLoading(true);

    let arr = [];
    let newContracts = [];
    let promises = [];

    // const worksheet = XLSX.utils.json_to_sheet(arr);
    // const workbook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(workbook, worksheet, "Report");
    // XLSX.writeFile(workbook, `zips.xlsb`);

    // excel.forEach(site => {
    //     let street = site['Street Address'];
    //     let city = site['City'];
    //     let state = site['State/Province'];

    //     // let address = site['Site ID'];
    //     // address = address.substring(8);
    //     // console.log(address);
    //     // let addressComps = address.split('.');
    //     // let state = addressComps[0];
    //     // let city = addressComps[1];
    //     // let street = addressComps[2];
    //     // console.log(address['Site ID'].substring(7, address['Site ID'].length))

    //     // add latitude and longitude to event location information
    //     let encodedAddress = `${street} ${city} ${state}`;
    //     encodedAddress = encodedAddress.replaceAll(' ', '%20');

    //     axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=zUbxRtqM2a7hjZAUOE3fLBR2nDtOFKtu`).then(res => {
    //         console.log(res);
    //         let zip = res.data.results[0]?.address_components?.filter(comp => comp.types.includes('postal_code'))[0]?.short_name;
    //         // let zip = obj[0];

    //         arr.push({
    //             ...site,
    //             ['Zip Code']: zip
    //         })

    //         console.log(arr);
    //     })
    // })

    for (let i = 0; i < excelJson.length; i++) {
      let contract = excelJson[i];

      let store = contract["Site Name/Store Number"];
      let address = contract["Street Address"];
      let city = contract["City"];
      let state = contract["State/Province"];
      let zipcode = contract["Zip code"];
      let client = contract["Master Client"];
      let subName = contract["Subcontractor Assigned"];
      let subPhone = contract["Sub Phone Number"];
      let siteName = contract["Site Name/Store Number"];

      let id = uuidv4();

      // add latitude and longitude to event location information
      let encodedAddress = `${address} ${city} ${state} ${zipcode || ""}`;
      encodedAddress = encodedAddress.replaceAll(" ", "%20");

      setTimeout(
        (function (x) {
          return function () {
            promises.push(
              axios
                .get(
                  `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=zUbxRtqM2a7hjZAUOE3fLBR2nDtOFKtu`
                )
                .then((res) => {
                  console.log(res);
                  if (res.data.results[0]) {
                    let coordinates = {
                      lat: res.data.results[0].geometry.location.lat,
                      lng: res.data.results[0].geometry.location.lng,
                    };
                    newContracts.push({
                      subName: subName || "",
                      client: client || "",
                      store: store || "",
                      address: address || "",
                      city: city || "",
                      zipcode: zipcode || "",
                      subPhone: subPhone || "",
                      id: id,
                      siteName: siteName || "",
                      lat: coordinates.lat,
                      lng: coordinates.lng,
                      state: state,
                    });
                  } else {
                    newContracts.push({
                      subName: subName || "",
                      client: client || "",
                      store: store || "",
                      address: address || "",
                      city: city || "",
                      zipcode: zipcode || "",
                      subPhone: subPhone || "",
                      id: id,
                      siteName: siteName || "",
                      state: state,
                    });
                  }
                })
                .then(() => {
                  if (i === excelJson.length - 1) {
                    console.log(newContracts);
                    arr = [...newContracts];

                    setBulkLoading(false);

                    axios
                      .post(
                        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=alerts&id=${locationId}`,
                        {
                          contracts: arr,
                        }
                      )
                      .then((res) => {
                        console.log(res);
                        setFilteredContracts(arr);
                        setBulkModalOpen(false);
                        setBulkLoading(false);
                      });
                  }
                })
            );
          };
        })(i),
        100 * i
      );
    }
  }

  const newBulkUpload = async () => {
    setBulkLoading(true);
    // compare excelJson to contracts
    // loop through excelJson
    // check if contract exists in contracts
    // if it does exist,
    // check if lat and lng exist
    // if not, get lat and lng from google maps api
    // check if webcamId exists
    // if not, get webcamId from windy api
    // check if webcamPlayer exists
    // if not, get webcamPlayer from windy api
    // check if subName, subPhone, and subEmail are the same
    // if not, update in contracts
    // if it doesn't exist
    // get lat, lng, webcamId, and webcamPlayer from google maps and windy api
    // add to contracts

    const getLatLongFromApi = async (contract) => {
      const { address, city, state, zipcode } = contract;

      // add latitude and longitude to event location information
      let encodedAddress = `${address || ""} ${city || ""} ${state || ""} ${
        zipcode || ""
      }`;
      encodedAddress = encodedAddress.replaceAll(" ", "%20");

      return axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=zUbxRtqM2a7hjZAUOE3fLBR2nDtOFKtu`
        )
        .then((res) => {
          if (res.data.results[0]) {
            let coordinates = {
              lat: res.data.results[0].geometry.location.lat,
              lng: res.data.results[0].geometry.location.lng,
            };

            return coordinates;
          }
          return null;
        });
    };

    const getWindyWebcamId = async (contract) => {
      try {
        const response = await axios.get(
          `https://my-tb-cors.herokuapp.com/https://api.windy.com/webcams/api/v3/webcams?lang=en&limit=50&offset=0&nearby=${contract.lat}%2C${contract.lng}%2C3&include=categories`,
          {
            headers: {
              "x-windy-api-key": "wh5zdd968j171JKy156RKkzaS9eA1OIz",
              accept: "application/json",
            },
          }
        );

        if (response.data.webcams.length > 0) {
          const webcam = response.data.webcams[0];
          const { webcamId } = webcam;
          return webcamId;
        }

        return null;
      } catch (error) {
        console.error("Error in getWindyWebcamId:", error);
        return null;
      }
    };

    const getWindyWebcamPlayer = async (webcamId) => {
      try {
        const response = await axios.get(
          `https://my-tb-cors.herokuapp.com/https://api.windy.com/webcams/api/v3/webcams/${webcamId}?lang=en&include=player`,
          {
            headers: {
              "x-windy-api-key": "wh5zdd968j171JKy156RKkzaS9eA1OIz",
              accept: "application/json",
            },
          }
        );

        return response.data?.player?.day;
      } catch (error) {
        console.error("Error in getWindyWebcamPlayer:", error);
        return null;
      }
    };

    async function updateContracts() {
      console.log("contracts length", contracts.length);
      console.log("excelJson length", excelJson.length);
      const updatedContracts = await Promise.all(
        excelJson.map(async (contract, index) => {
          let updatedContract = contracts.find(
            (oldContract) =>
              oldContract.store === contract["Site Name/Store Number"] &&
              oldContract.client === contract["Master Client"]
          );

          if (updatedContract) {
            if (!updatedContract.lat || !updatedContract.lng) {
              // let coordinates = await getLatLongFromApi(updatedContract);
              // if (coordinates) {
              //   updatedContract.lat = coordinates.lat;
              //   updatedContract.lng = coordinates.lng;
              //   if (!updatedContract.webcamId) {
              //     // get webcamId from windy api
              //   }
              //}
            } else {
              if (!updatedContract.webcamId) {
                // get webcamId from windy api
                const webcamId = await getWindyWebcamId(updatedContract);
                if (webcamId) {
                  // get webcamPlayer from windy api
                  const webcamPlayer = await getWindyWebcamPlayer(webcamId);
                  updatedContract = {
                    ...updatedContract,
                    webcamId: webcamId,
                    webcamPlayer: webcamPlayer,
                  };
                }
              } else {
                if (!updatedContract.webcamPlayer) {
                  // get webcamPlayer from windy api
                  const webcamPlayer = await getWindyWebcamPlayer(
                    updatedContract.webcamId
                  );
                  updatedContract = {
                    ...updatedContract,
                    webcamPlayer: webcamPlayer,
                  };
                }
              }
            }

            console.log(index);
            console.log(updatedContract.store);
            return {
              ...updatedContract,
              store: contract["Site Name/Store Number"],
              address: contract["Street Address"],
              city: contract["City"],
              state: contract["State/Province"],
              zipcode: contract["Zip code"],
              client: contract["Master Client"],
              subName: contract["Subcontractor Assigned"],
              subPhone: contract["Sub Phone Number"],
              siteName: contract["Site Name/Store Number"],
            };
          } else {
            return {
              store: contract["Site Name/Store Number"],
              address: contract["Street Address"],
              city: contract["City"],
              state: contract["State/Province"],
              zipcode: contract["Zip code"],
              client: contract["Master Client"],
              subName: contract["Subcontractor Assigned"],
              subPhone: contract["Sub Phone Number"],
              siteName: contract["Site Name/Store Number"],
              id: uuidv4(),
            };
          }
        })
      );

      // Remove potential duplicates

      console.log(updatedContracts);

      return updatedContracts;
    }

    // Call the function asynchronously
    updateContracts().then((result) => {
      console.log("done");
      // Use the updated contracts
      console.log(result);

      axios
        .post(
          `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=alerts&id=${locationId}`,
          {
            contracts: result,
          }
        )
        .then((res) => {
          console.log(res);
          setFilteredContracts(result);
          setBulkModalOpen(false);
          setBulkLoading(false);
        });
    });
  };

  function checkExcel(json) {
    let keys = Object.keys(json[0]);
    let missingCols = [];

    requiredCols.forEach((col) => {
      if (keys.indexOf(col) === -1) {
        missingCols.push(col);
      }
    });

    return missingCols;
  }

  // async function updateContracts() {
  //   // Assuming you have an API function to get lat and lng based on some identifier
  //   // Replace 'getLatLongFromApi' with the actual function you have for API call
  //   const getLatLongFromApi = async (identifier) => {
  //     // Make your API call here and return the result
  //     // Example: const response = await fetch(`your-api-endpoint/${identifier}`);
  //     // const data = await response.json();
  //     // return { lat: data.latitude, lng: data.longitude };
  //   };

  //   const updateArrayWithLatLong = async (oldArray, updatedArray) => {
  //     const newArray = [...oldArray];

  //     // Update existing elements in oldArray
  //     for (const oldElement of newArray) {
  //       if (!("lat" in oldElement) || !("lng" in oldElement)) {
  //         // If lat and lng are not defined, make an API call to get them
  //         const { lat, lng } = await getLatLongFromApi(oldElement.identifier);
  //         oldElement.lat = lat;
  //         oldElement.lng = lng;
  //       }
  //     }

  //     // Add new elements from updatedArray to oldArray
  //     for (const updatedElement of updatedArray) {
  //       const existsInOldArray = newArray.some(
  //         (oldElement) => oldElement.identifier === updatedElement.identifier
  //       );

  //       if (!existsInOldArray) {
  //         // If the element doesn't exist in oldArray, get lat and lng and add it
  //         const { lat, lng } = await getLatLongFromApi(
  //           updatedElement.identifier
  //         );
  //         newArray.push({ ...updatedElement, lat, lng });
  //       }
  //     }

  //     // Remove elements from oldArray that don't exist in updatedArray
  //     newArray.forEach((oldElement, index) => {
  //       const existsInUpdatedArray = updatedArray.some(
  //         (updatedElement) =>
  //           updatedElement.identifier === oldElement.identifier
  //       );

  //       if (!existsInUpdatedArray) {
  //         newArray.splice(index, 1);
  //       }
  //     });

  //     return newArray;
  //   };

  //   // Example usage:
  //   const oldArray = [
  //     { identifier: "1", lat: 12.34, lng: 56.78 },
  //     { identifier: "2", lat: 23.45, lng: 67.89 },
  //     // ... other elements
  //   ];

  //   const updatedArray = [
  //     { identifier: "1" /* other properties */ },
  //     { identifier: "3" /* other properties */ },
  //     // ... other elements
  //   ];

  //   const resultArray = await updateArrayWithLatLong(oldArray, updatedArray);
  //   console.log(resultArray);
  // }

  function readFileUpload(e) {
    let reader = new FileReader();

    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      console.log(json);

      console.log([
        ...json.map((company) => company["Tenant Name"]),
        // ...json.map((company) => company["Parent Company"]),
      ]);

      setExcelJson(json);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  }

  function saveContract(contract) {
    let { Client, Address, City, Zipcode, lat, lng } = contract;

    if (
      Client.length === 0 ||
      Address.length === 0 ||
      City.length === 0 ||
      Zipcode === 0 ||
      lat.length === 0 ||
      lng.length === 0
    ) {
      setError("This field is required");
    } else {
      let id = uuidv4();
      contract.id = id;

      let arr = [contract, ...contracts];

      axios
        .post(
          `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=alerts&id=${locationId}`,
          {
            contracts: arr,
          }
        )
        .catch((err) => {
          alert("Oops! There was an error. Please try again");
        })
        .then((res) => {
          setContracts([contract, ...filteredContracts]);
          setFilteredContracts([contract, ...filteredContracts]);
          handleUploadClose();
        });
    }
  }

  function updateContract(contract) {
    let arr = contracts.map((item) => {
      if (item.id === contract.id) {
        return contract;
      }
      return item;
    });

    let filteredArr = filteredContracts.map((item) => {
      if (item.id === contract.id) {
        return contract;
      }
      return item;
    });

    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=alerts&id=${locationId}`,
        {
          contracts: arr,
        }
      )
      .catch((err) => {
        alert("Oops! There was an error. Please try again");
      })
      .then((res) => {
        setContracts(arr);
        setFilteredContracts(filteredArr);
        setEditModalOpen(false);
      });
  }

  function addSubs() {
    let all = contracts;

    all.forEach((contract) => {
      if (contract.Client === "711") {
        let sub = subs.filter((item) => item.Store === contract.Store)[0];

        if (sub) {
          contract.subName = sub["Name of Sub Signed"];
          contract.subEmail = sub["Email address"];
          contract.subPhone = sub["Contact Number"];
        }
      }
    });

    console.log(all);

    axios
      .post(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/update?containerId=alerts&id=${locationId}`,
        {
          contracts: all,
        }
      )
      .then((res) => console.log(res));
  }

  function changeSubs() {
    let all = contracts;
    all.forEach((contract) => {
      let arr = newJson.filter(
        (item) => parseInt(item["Site Name"]) === parseInt(contract.Store)
      );
      // console.log(arr);
      if (arr.length > 0) {
        contract.subbed = arr[0]["2022-23 Sub"];
        contract.subName = arr[0]["2022-23 Sub"];
        contract.subPhone = arr[0]["2022-23 Sub Phone"];
        contract.subEmail = arr[0]["2022-23 Sub Email"];
        console.log(arr[0]["2022-23 Sub"]);
      }
      // for(let i=0; i<newJson.length; i++) {
      //     console.log(contract.Store === newJson[i].Store)
      //     if(contract.Store === newJson[i].Store) {
      //         contract['subbed'] = newJson[i]['Sub Leads'].trim()
      //     }
      // }
    });

    console.log(all);
  }

  function getName() {
    // https://www.mapquestapi.com/search/v4/place?location=-88.32467419999999%2C42.22516&sort=relevance&feedback=false&key=vrhZilUDKVobwePrDx9cvGqLqbgZi6sz&q=store
    let promises = [];
    let sliced = arr.slice(0, 5);
    sliced.forEach((location, index) => {
      if (location.coordinates) {
        setTimeout(
          (function (x) {
            return function () {
              promises.push(
                axios
                  .get(
                    `https://www.mapquestapi.com/search/v4/place?location=${location.coordinates}&sort=relevance&feedback=false&key=vrhZilUDKVobwePrDx9cvGqLqbgZi6sz&q=store`
                  )
                  .then((res) => {
                    console.log(res);

                    if (res.data.results.length > 0) {
                      sliced[index].company = res.data.results[0].name;
                    }
                  })
                  .then(() => {
                    if (index === sliced.length - 1) {
                      console.log(sliced);
                    }
                  })
              );
            };
          })(index),
          100 * index
        );
      }
    });
  }

  function generateExcel() {
    const worksheet = XLSX.utils.json_to_sheet(excel);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Stores");
    XLSX.writeFile(workbook, `stores.xlsb`);
  }

  function getArray(e) {
    // //console.log(arr);
    arr.forEach((location, index) => {
      if (
        walgreens.filter(
          (obj) =>
            obj.store.toString() === location["Store Number"] &&
            location["Zip Code"].indexOf(obj.zipcode.toString()) > -1
        ).length > 0
      ) {
        console.log("Walgreens");
        arr[index].company = "Walgreens";
      }
    });

    console.log(arr);

    // const reader = new FileReader();
    // reader.onload = (e) => {
    //     const data = e.target.result;
    //     const workbook = XLSX.read(data, {type: 'array'});
    //     const sheetName = workbook.SheetNames[0];
    //     const worksheet = workbook.Sheets[sheetName];
    //     const json = XLSX.utils.sheet_to_json(worksheet);

    //     console.log(json);

    //     console.log(json.map(obj => {
    //         return {
    //             store: obj['Store No.'],
    //             address: obj['Street'],
    //             zipcode: obj['Zip_Code']
    //         }
    //     }))
    // }
    // reader.readAsArrayBuffer(e.target.files[0]);
  }

  function removeCompanies() {
    let lowerCase = uploaded.map((company) =>
      company
        .toString()
        .toLowerCase()
        .replaceAll("company", "")
        .replaceAll("&", "and")
        .replaceAll("co.", "")
        .replaceAll("corporation", "")
        .replaceAll("inc.", "")
        .replaceAll("incorporated", "")
        .replaceAll("llc", "")
        .replaceAll("pllc", "")
        .replaceAll("group", "")
        .replaceAll("ltd.", "")
        .replace(/[^\w\s]/gi, "")
        .trim()
    );
    let notExported = allCompanies.filter(
      (company) =>
        !lowerCase.includes(
          company
            .toString()
            .toLowerCase()
            .replaceAll("company", "")
            .replaceAll("&", "and")
            .replaceAll("co.", "")
            .replaceAll("corporation", "")
            .replaceAll("inc.", "")
            .replaceAll("incorporated", "")
            .replaceAll("llc", "")
            .replaceAll("pllc", "")
            .replaceAll("group", "")
            .replaceAll("ltd.", "")
            .replace(/[^\w\s]/gi, "")
            .trim()
        )
    );
    console.log(notExported);
  }

  function readExcel(e) {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);

      console.log(json);

      //setNewJson(json);

      let promises = [];

      let arr = json.slice(11000);

      arr.forEach((location, index) => {
        let Address = location["Street Address"];
        let City = location["City"];
        let Zipcode = "";
        if (location["Zip Code"]) {
          Zipcode = location["Zip Code"].toString().slice(0, 5);
        }
        // add latitude and longitude to event location information
        let encodedAddress = `${Address} ${City} ${Zipcode || ""}`;
        encodedAddress = encodedAddress.replaceAll(" ", "%20");

        setTimeout(
          (function (x) {
            return function () {
              promises.push(
                axios
                  .get(
                    `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=zUbxRtqM2a7hjZAUOE3fLBR2nDtOFKtu`
                  )
                  .then((res) => {
                    console.log(res);
                    if (res.data.results[0]) {
                      let coordinates = {
                        lat: res.data.results[0].geometry.location.lat,
                        lng: res.data.results[0].geometry.location.lng,
                      };
                      arr[
                        index
                      ].coordinates = `${coordinates.lng},${coordinates.lat}`;
                    }
                  })
                  .then(() => {
                    if (index === arr.length - 1) {
                      console.log(arr);
                    }
                  })
              );
            };
          })(index),
          100 * index
        );
      });

      // let arr = json.slice(0, 500);
      // arr.forEach((location, index) => {
      // let Address = location['Street Address'];
      // let City = location['City'];
      // let Zipcode = '';
      // if(location['Zip Code']) {
      //     Zipcode = location['Zipcode'].toString().slice(0, 5);
      // }
      //  // add latitude and longitude to event location information
      // let encodedAddress = `${Address} ${City} ${Zipcode || ''}`;
      // encodedAddress = encodedAddress.replaceAll(' ', '%20');

      //     axios.get(`https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=zUbxRtqM2a7hjZAUOE3fLBR2nDtOFKtu`).then(res => {
      //         //console.log(res.data);
      //         //bulkupload
      //         if(res.data.results[0]) {
      //             let lat = res.data.results[0].geometry.location.lat;
      //             let lng = res.data.results[0].geometry.location.lng;

      //             // arr[index].lat = lat;
      //             // arr[index].lng = lng;

      //             console.log(`${lng},${lat}`);

      //             arr[index]['__EMPTY_18'] = `${lng},${lat}`;
      //         }
      //     })

      //     if(index === arr.length - 1) {
      //         console.log(arr);
      //     }
      // })

      // console.log(arr);
    };
    reader.readAsArrayBuffer(e.target.files[0]);
  }

  useEffect(() => {
    console.log(excelJson);
  }, [excelJson]);

  return (
    <>
      <Modal open={editModalOpen} onClose={() => setEditModalOpen(false)}>
        <Box
          sx={{
            ...style,
            width: "90%",
            maxWidth: "600px",
            p: 3,
            maxHeight: "90%",
            overflowY: "scroll",
          }}
        >
          <Typography variant="h5" sx={{ mb: 3 }}>
            Edit Contract
          </Typography>
          <EditContract
            updateContract={updateContract}
            error={error}
            setError={setError}
            setEditModalOpen={setEditModalOpen}
            initialValues={activeContract}
          />
        </Box>
      </Modal>
      <Modal open={uploadModalOpen} onClose={() => setUploadModalOpen(false)}>
        <Box
          sx={{
            ...style,
            width: "90%",
            maxWidth: "600px",
            p: 3,
            maxHeight: "90%",
            overflowY: "scroll",
          }}
        >
          <Typography variant="h5" sx={{ mb: 3 }}>
            Add a Contract
          </Typography>
          <NewContract
            saveContract={saveContract}
            error={error}
            setError={setError}
            setUploadModalOpen={setUploadModalOpen}
            initialValues={{
              Client: "",
              Store: "",
              Address: "",
              City: "",
              Zipcode: "",
              subPhone: "",
              subEmail: "",
              lat: "",
              lng: "",
              onCall: false,
              url: "",
            }}
          />
        </Box>
      </Modal>
      <Modal open={bulkModalOpen} onClose={() => setBulkModalOpen(false)}>
        <Box
          sx={{
            ...style,
            width: "90%",
            maxWidth: "600px",
            p: 3,
            maxHeight: "90%",
            overflowY: "scroll",
          }}
        >
          <Typography variant="h5" sx={{ mb: 2 }}>
            Bulk Upload Contracts
          </Typography>

          <Divider sx={{ mb: 3 }} />

          <label>Select Excel file to upload</label>
          <br />
          <input type="file" onChange={readFileUpload} />

          <Typography color="error" variant="body2">
            {excelError}
          </Typography>

          {Object.keys(excelJson).length > 0 && (
            <Box sx={{ my: 3 }}>
              {/* <Divider sx={{mb: 3}} />
                            <FormControl>
                                <FormLabel 
                                    id="upload-method"
                                >
                                    How would you like to upload these contracts?
                                </FormLabel>
                                <RadioGroup
                                    defaultValue="append"
                                    name="upload-method"
                                    onChange={(e) => setUploadMethod(e.target.value)}
                                >
                                    <FormControlLabel value="append" control={<Radio />} label="Add these contracts to existing contracts" />
                                    <FormControlLabel value="replace" control={<Radio />} label="Replace all contracts" />
                                </RadioGroup>
                            </FormControl> */}

              <ButtonBox>
                <Button
                  sx={{ fontSize: "10px", mr: 1, mt: 2 }}
                  variant="contained"
                  color="error"
                  onClick={() => setBulkModalOpen(false)}
                >
                  cancel
                </Button>

                <Box sx={{ position: "relative", mt: 2 }}>
                  <Button
                    sx={{ fontSize: "10px" }}
                    variant="contained"
                    color="success"
                    onClick={newBulkUpload}
                    disabled={bulkLoading}
                  >
                    bulk upload
                  </Button>
                  {bulkLoading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "#ed6a22",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </ButtonBox>
              {bulkLoading && (
                <Typography color="error" sx={{ fontWeight: "bold", mt: 2 }}>
                  Do NOT close this window until the loading symbol has
                  disappeared
                </Typography>
              )}
            </Box>
          )}
        </Box>
      </Modal>
      <Modal open={deleteModalOpen} onClose={() => setDeleteModalOpen(false)}>
        <Box sx={{ ...style, maxWidth: "400px", p: 3 }}>
          <Typography variant="h5">
            Are you sure you want to delete the selected items?
          </Typography>
          <Typography variant="body2" sx={{ mt: 2 }}>
            This action cannot be undone
          </Typography>
          <ButtonBox>
            <Button
              sx={{ fontSize: "10px", mt: 3, mr: 1 }}
              variant="contained"
              onClick={deleteSelected}
              color="error"
            >
              yes, delete
            </Button>
            <Button
              sx={{ fontSize: "10px", mt: 3 }}
              variant="contained"
              onClick={() => setDeleteModalOpen(false)}
              color="success"
            >
              no, cancel
            </Button>
          </ButtonBox>
        </Box>
      </Modal>
      <PageLayout page="Contracts">
        {/* <input type='file' onChange={getArray} />
                <Button onClick={generateExcel}>change</Button> */}
        <Paper sx={{ p: 3, mb: 2 }}>
          <Grid container>
            <Grid item xs={6} my="auto">
              <Typography>{location.toUpperCase()} SNOW CONTRACTS</Typography>
              {/* <Button onClick={removeCompanies}>get companies</Button> */}
            </Grid>
            <Grid item xs={6} my="auto">
              {/* <Button onClick={addSubs}>add</Button> */}
              <ButtonBox>
                <FormGroup row>
                  <TextField
                    type="search"
                    label="Search..."
                    size="small"
                    value={searchTerm}
                    onChange={handleSearch}
                    onKeyDown={(event) => {
                      if (event.code === "Enter") {
                        search();
                      }
                    }}
                  />
                  <IconButton onClick={search}>
                    <Search />
                  </IconButton>
                </FormGroup>
              </ButtonBox>
            </Grid>
          </Grid>
        </Paper>
        <Paper sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs={6}></Grid>
            <Grid item xs={6}>
              <ButtonBox>
                <Button
                  sx={{ fontSize: "10px", mr: 1 }}
                  variant="contained"
                  onClick={handleUploadOpen}
                >
                  add contract
                </Button>
                <Button
                  sx={{ fontSize: "10px", mr: 1 }}
                  variant="contained"
                  onClick={() => setBulkModalOpen(true)}
                >
                  bulk upload contracts
                </Button>
                <Button
                  sx={{ fontSize: "10px" }}
                  variant="contained"
                  color="error"
                  onClick={() => setDeleteModalOpen(true)}
                  disabled={selected.length === 0}
                >
                  delete
                </Button>
              </ButtonBox>
            </Grid>
          </Grid>
          <div
            style={{
              height: "calc(100vh - 100px)",
              width: "100%",
              marginTop: "10px",
            }}
          >
            <DataGridPro
              sx={{ bgcolor: "white" }}
              columns={columns}
              rows={filteredContracts}
              loading={loading}
              disableSelectionOnClick
              checkboxSelection
              //onRowClick={(params) => redirect(params)}
              density="compact"
              pagination
              pageSize={50}
              onSelectionModelChange={selectRows}
            />
          </div>
        </Paper>
      </PageLayout>
    </>
  );
}

export default Contracts;
