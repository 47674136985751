import React from "react";
import { useEffect, useState } from "react";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import { styled, useTheme } from "@mui/material/styles";
import {
  AcUnit,
  CalendarMonth,
  Description,
  Map,
  NoteAdd,
  Air,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Camera } from "@mui/icons-material";

function PageLayoutLogic() {
  const navigate = useNavigate();
  const drawerWidth = 240;
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const userId = localStorage.getItem("userId");
  // const franchiseSupport = localStorage.getItem('franSupport');
  // const franOwner = localStorage.getItem('franOwner');

  useEffect(() => {
    if (!userId) {
      navigate("/");
    }
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
  });

  const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
      ...openedMixin(theme),
      "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      "& .MuiDrawer-paper": closedMixin(theme),
    }),
  }));

  let navLinks = [
    {
      text: "Contracts",
      href: "/contracts",
      icon: <Description />,
    },
    {
      text: "Windy Map",
      href: "/windy",
      icon: <Air />,
    },
    {
      text: "Web Cams",
      href: "/webcams",
      icon: <Camera />,
    },
  ];

  function logout() {
    localStorage.removeItem("msa");
    localStorage.removeItem("access token");
    localStorage.removeItem("user");
    localStorage.removeItem("email");

    navigate("/");
  }

  return {
    theme,
    open,
    AppBar,
    DrawerHeader,
    Drawer,
    navLinks,
    handleDrawerClose,
    handleDrawerOpen,
    logout,
  };
}

export default PageLayoutLogic;
