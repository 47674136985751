import * as msal from "@azure/msal-browser";
import axios from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function LoginLogic() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const msalConfig = {
    auth: {
      clientId: process.env.REACT_APP_MSAL_CLIENTID,
    },
  };

  const msalInstance = new msal.PublicClientApplication(msalConfig);

  async function msalLogin() {
    try {
      // need to get users role and which location they're logging in from
      const loginResponse = await msalInstance.loginPopup({});

      let token = loginResponse.accessToken;
      localStorage.setItem("account", JSON.stringify(loginResponse.account));
      localStorage.setItem("access token", loginResponse.accessToken);

      // microsoft graphql call to get user info and officeLocation
      return axios
        .get(
          `https://graph.microsoft.com/v1.0/users/${loginResponse.account.username}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          let user = res.data;
          let location;

          // get location of user
          if (
            !user.officeLocation ||
            user.officeLocation === "Operations" ||
            user.officeLocation === "HQ"
          ) {
            location = "Monroe";
          } else {
            location = user.officeLocation;
          }

          localStorage.setItem("location", "Monroe");
          localStorage.setItem("userId", user.id);

          return {
            data: "",
          };
        });
    } catch (err) {
      console.log(err);
      // handle error
      return {
        data: "failed login",
      };
    }
  }

  async function login() {
    let loginResponse = await msalLogin();

    console.log(loginResponse);

    if (loginResponse.data === "failed login") {
      alert("Incorrect login, please try again");
    } else {
      localStorage.setItem("lastLoginTime", new Date().getTime());
      navigate("/contracts");
    }
  }

  return {
    login,
    open,
  };
}

export default LoginLogic;
