import React, { useState } from "react";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "mapbox-gl";
import { useRef } from "react";
import { useEffect } from "react";
import axios from "axios";
import PageLayout from "../../components/pageLayout/pageLayout";
import {
  Autocomplete,
  Divider,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import location from "../../images/location.png";
import "./style.css";

mapboxgl.accessToken =
  "pk.eyJ1Ijoic2FyYWhtYXJpZWNhcnRlciIsImEiOiJja3A4dGE2eGUwYmhhMm9ueHQxYm81aG1jIn0.IL6ap7-sSL2WXIipenrzUQ";

function NewMap() {
  const map = useRef(null);
  const mapContainer = useRef(null);
  const [center, setCenter] = useState([-103.77155, 44.96724]);
  const [zoom, setZoom] = useState(4);
  const [activeEvent, setActiveEvent] = useState(null);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState("All Clients");
  const [events, setEvents] = useState([]);
  const [geojson, setGeojson] = useState({
    type: "FeatureCollection",
    features: [],
  });

  useEffect(() => {
    axios
      .get(
        `https://my-tb-cors.herokuapp.com/https://connect-fns2.azurewebsites.net/api/getall?containerId=alerts`
      )
      .then((res) => {
        console.log([
          ...new Set(res.data[0].contracts.map((site) => site.client).sort()),
        ]);

        setClients([
          ...new Set(res.data[0].contracts.map((site) => site.client).sort()),
        ]);
        let arr = res.data[0].contracts.filter((contract) => contract.lat);
        setFilteredEvents(arr);
        setEvents(arr);
      });
  }, []);

  useEffect(() => {
    const arr = filteredEvents.map((event) => {
      return {
        type: "Feature",
        properties: {
          client: event.client,
          store: event.store,
          address: event.address,
          city: event.city,
          state: event.state,
          zipcode: event.zipcode,
          subName: event.subName,
          subPhone: event.subPhone,
        },
        geometry: {
          type: "Point",
          coordinates: [event.lng, event.lat],
        },
      };
    });
    setGeojson({
      ...geojson,
      features: arr,
    });

    console.log(arr);
  }, [filteredEvents]);

  useEffect(() => {
    if (
      map.current ||
      filteredEvents.length === 0 ||
      geojson.features.length === 0
    )
      return; // initialize map only once

    // set up a promise for The Weather Company product metadata
    const timeSlices = fetch(
      "https://api.weather.com/v3/TileServer/series/productSet/PPAcore?apiKey=" +
        "9273f14c853b4634b3f14c853bd634ab"
    );

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: center,
      zoom: zoom,
    });

    // Add our navigation control (the +/- zoom buttons)
    map.current.addControl(new mapboxgl.NavigationControl(), "top-right");

    map.current.on("load", () => {
      timeSlices
        .then((res) => res.json())
        .then((res) => {
          const radarTimeSlices = res.seriesInfo.radar.series;
          const latestTimeSlice = radarTimeSlices[0].ts;

          let subbed = geojson.features.filter(
            (store) => store.properties.subName
          );
          let unsubbed = geojson.features.filter(
            (store) => !store.properties.subName
          );

          map.current.addSource("unsubbed", {
            type: "geojson",
            data: {
              ...geojson,
              features: unsubbed,
            },
          });

          // Add a circle layer
          map.current.addLayer({
            id: "unsubbed",
            type: "circle",
            source: "unsubbed",
            paint: {
              "circle-radius": 4,
              "circle-color": "#B42222",
            },
          });

          map.current.addSource("subbed", {
            type: "geojson",
            data: {
              ...geojson,
              features: subbed,
            },
          });

          // Add a symbol layer
          map.current.addLayer({
            id: "subbed",
            type: "circle",
            source: "subbed",
            paint: {
              "circle-radius": 4,
              "circle-color": "green",
            },
          });

          map.current.addSource("twcRadar", {
            type: "raster",
            tiles: [
              `https://api.weather.com/v3/TileServer/tile/radar?ts=${latestTimeSlice}&xyz={x}:{y}:{z}&apiKey=9273f14c853b4634b3f14c853bd634ab`,
            ],
            tileSize: 256,
          });

          map.current.addLayer(
            {
              id: "radar",
              type: "raster",
              source: "twcRadar",
              paint: {
                "raster-opacity": 0.85,
              },
            },
            "aeroway-line"
          );

          console.log(filteredEvents);

          map.current.on("click", "subbed", function (e) {
            console.log(e);
            const coordinates = e.features[0].geometry.coordinates.slice();
            console.log(coordinates);
            const {
              client,
              store,
              address,
              city,
              state,
              zipcode,
              subName,
              subPhone,
            } = e.features[0].properties;
            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(
                `Client: ${client}<br />
                                    Store: ${store}<br />
                                    Address: ${address}<br />
                                    City: ${city}, ${state} ${zipcode}<br />
                                    Zip code: ${zipcode}<hr />
                                    Sub: ${subName || "None assigned"}<br />
                                    Sub Phone: ${subPhone || ""}<br />`
              )
              .addTo(map.current);
          });

          map.current.on("click", "unsubbed", function (e) {
            console.log(e);
            const coordinates = e.features[0].geometry.coordinates.slice();
            console.log(coordinates);
            const {
              client,
              store,
              address,
              city,
              state,
              zipcode,
              subName,
              subPhone,
            } = e.features[0].properties;
            new mapboxgl.Popup()
              .setLngLat(coordinates)
              .setHTML(
                `Client: ${client}<br />
                                    Store: ${store}<br />
                                    Address: ${address}<br />
                                    City: ${city}, ${state} ${zipcode}<br />
                                    Zip code: ${zipcode}<hr />
                                    Sub: ${subName || "None assigned"}<br />
                                    Sub Phone: ${subPhone || ""}<br />`
              )
              .addTo(map.current);
          });
        });
    });
  }, [geojson]);

  useEffect(() => {
    console.log(client);
    if (map.current) {
      let newGeojson = { ...geojson };

      if (client === "All Clients") {
        newGeojson.features = geojson.features;
      } else if (client === "Washington Clients") {
        newGeojson.features = geojson.features.filter(
          (feature) => feature.properties.state === "WA"
        );
      } else {
        newGeojson.features = geojson.features.filter(
          (feature) => feature.properties.client === client
        );
      }

      map.current.getSource("subbed").setData({
        ...newGeojson,
        features: newGeojson.features.filter(
          (feature) => feature.properties.subName
        ),
      });
      map.current.getSource("unsubbed").setData({
        ...newGeojson,
        features: newGeojson.features.filter(
          (feature) => !feature.properties.subName
        ),
      });
    }
  }, [client]);

  const handleFilter = (e, newValue) => {
    setClient(newValue);
  };
  return (
    <>
      <PageLayout page="Map">
        <Autocomplete
          value={client}
          options={["All Clients", "Washington Clients", ...clients]}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Filter by Client"
              size="small"
              sx={{ mb: 2, width: "400px", bgcolor: "white" }}
              // fullWidth
              // error={formValues.property === '' && submitted}
            />
          )}
          onChange={handleFilter}
        />
        <Grid container>
          <Grid item xs={12}>
            <div
              ref={mapContainer}
              style={{ width: "100%", height: "100vh" }}
            />
          </Grid>
        </Grid>
      </PageLayout>
    </>
  );
}

export default NewMap;
